body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.px1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.group {
  display: flex;
  justify-content: space-between;
}

.popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .2);
  width: 100vw;
  pointer-events: none;
  height: 100vh;
  opacity: .7;
  z-index: 1299;
}

.button-popup {
  position: absolute;
  min-width: 350px;
  max-width: 1000px;
  text-align: center;
  top: calc(100% + 2px);
  z-index: 1299;
  left: 0;
  background: #fff;
  color: #000;
}

.button-popup .MuiTypography-body1 {
  padding: 10px;
}

.button-popup .bottom-buttons-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.button-popup .bottom-buttons-content button {
  color: #36b17e;
}
.button-popup .bottom-buttons-content .popup-steps {
  padding: 4px 5px;
  width: 100%;
}
.button-popup .header {
  background-color: #36b17e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 10px;
  font-size: 13px;
}

.button-popup .header svg {
  cursor: pointer;
  margin-left: auto;
}

.highlighted-popup-container .highlighted {
    position: relative;
    z-index: 1299;
    border-color: #36b17e;
}

.sidebar-drawer .MuiTypography-root {
  font-weight: 500;
}

button:focus {
  outline: none !important;
}

div[role="tooltip"] {
  z-index: 9;
}

.select__indicator {
   padding: 8px 4px !important;
}

.select__indicator svg {
  fill: #4f4f4f;
}