* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    color: #4f4f4f;
    width: 100%;
    height: 100%;
}

h1, h2, h3, h4, h5, h6, span, label {
    margin: 0;
}

p {
    margin: 0 !important;
}

a {
    color: #1b75ba;
    text-decoration: none;
    cursor: pointer;
}

.authorDocsTable {
    width: 100%;
}

a:hover {
    color: #23527c;
    cursor: pointer;
    text-decoration: none !important;
}

ul {
    list-style-type: none;
    margin: 0;
}

button:not(.active):not(.disabled):not(:disabled):not(:active) {
    cursor: pointer;
}

input, button {
    background: none;
    border: none;
    outline: none;
}

input:focus, button:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none !important;
}

input:hover, button:hover {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

select:focus, textarea:focus, button:focus {
    outline: none !important;
    box-shadow: none !important;
}

/*16px = 1em = 1rem*/

html {
    font-size: 100%;
}

.fs11 {
    font-size: 11px;
}

.fs12 {
    font-size: 12px;
}

.fs13 {
    font-size: 13px;
}

.fs14 {
    font-size: 14px;
}

.fs16 {
    font-size: 1rem;
}

.fs17 {
    font-size: 1.063rem;
}

.fs18 {
    font-size: 1.125rem;
}

.fs19 {
    font-size: 1.188rem;
}

.fs20 {
    font-size: 1.25rem;
}

.fs21 {
    font-size: 1.313rem;
}

.fs22 {
    font-size: 1.375rem;
}

.fs23 {
    font-size: 1.438rem;
}

.fs24 {
    font-size: 1.5rem;
}

.fs25 {
    font-size: 1.563rem;
}

.fs26 {
    font-size: 1.625rem;
}

.fs27 {
    font-size: 1.688rem;
}

.fs28 {
    font-size: 1.75rem;
}

.fs29 {
    font-size: 1.813rem;
}

.fs30 {
    font-size: 1.875rem;
}

.fs31 {
    font-size: 1.938rem;
}

.fs32 {
    font-size: 2rem;
}

.scroller-block {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.text-primary {
    color: #06c;
}

.text-secondary {
    color: #4f4f4f !important;
}

.text-white {
    color: #fff !important;
}

.cursor-pointer {
    cursor: pointer;
}

/* Dash-Board*/

.hour {
    font-size: larger;
    margin-top: 0;
}

.dashboard_header .header_title, .dashboard_header .date {
    display: flex;
}


/* Delete Modal  */
/* Delete Modal  */
.ReactModal__Content {
    border: none !important;
    position: fixed;
    margin: -7%;
    backdrop-filter: blur(5px);
}

.ReactModal__Overlay {
    opacity: 0;
    z-index: 1000;
}

.ReactModal__Overlay--after-open {
    opacity: 0.9;
    position: fixed;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.dangericon {
    margin-top: 200px;
    margin-left: 250px;
}

.delmsg1 {
    color: red;
    display: flex;
    margin: 0 auto 30px;
    flex-wrap: wrap;
}

.delmsg1 > h4 {
    font-weight: 500;
}

.delmdg2 {
    color: blue;
    padding-left: 25px;
    margin: 0 auto 15px;
    text-align: center;
}

.__react_component_tooltip {
    background-color: #707070 !important;
    color: #fff !important;
    opacity: 1 !important;
    font-weight: bold !important;
}

.__react_component_tooltip:after {
    border-right-color: #707070 !important;
}

input, textarea {
    padding-left: 10px;
}

/* Modal styles */

.ReactModal__Overlay--after-open {
    background-color: rgba(0,0,0, 0.5) !important;
}

.assign-doc-modal .MuiDialog-paper {
    padding: 20px;
    max-width: 830px;
    background-color: #fff;
    margin: 5% auto !important;
    border-radius: 0 !important;
    inset: 20px !important;
    min-width: 800px;
    min-height: 61vh;
    max-height: 69vh;
}

.ReactModal__Content .modal-header {
    background-color: #0066cc;
    display: flex;
    align-items: center;
    margin-top: -20px;
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0;
}

.ReactModal__Content .modal-header span {
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}

.ReactModal__Content .modal-header svg {
    fill: #fff;
}

.ReactModal__Content .modal-header .close-icon {
    cursor: pointer;
}

@media all and (max-height: 910px) {
    .ReactModal__Content.assign-doc-modal.assign-doc-modal {
        margin: 20px auto !important;
    }
}

@media all and (max-height: 740px) {
    .ReactModal__Content.assign-doc-modal.assign-doc-modal {
        min-height: 84vh !important;
        max-height: 86vh !important;
    }
}