@import "./mixins";
@import "./variables";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

// Table styles
html, body {
  font-family: 'Lato', sans-serif;
  font-weight: 500;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #0066cc;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.sort-arrow {
  position: absolute;
  cursor: pointer;
  opacity: 0;

  // &:hover {
  //   opacity: 1 !important;
  // }
}

.project-list {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #fff;
  z-index: 10;
  height: auto;
  top: 5px;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 25%);
  border-radius: 12px;
  min-width: 212px;
  max-height: 390px;
  overflow-y: scroll;
  cursor: pointer;

  .item {
    border-bottom: 1px solid #dcdfe2;
    padding: 15px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    align-items: center;
    color: #4f4f4f;
    text-transform: uppercase;
  }
}

.project-count {
  color: #0367CC;
  padding: 2px 5px;
  text-align: center;
  font-size: 16px;
  margin: 1px 7px;
  word-break: keep-all;

  &:before {
    counter-increment: button-numbers;
    content: '+';
    padding: 7px 0 8px 0;
    font-size: 16px;
    font-weight: 700;
  }
}

.manageTemplatesTable div.row,
.authorDocsTable div.row {
  margin-bottom: 2px;
}

.authorDocsTable table.table th,
.manageTemplatesTable table.table th {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: white;
  color: black;
  font-size: x-small;
  font-weight: bold;
  border-top: #395978;
  border-bottom-color: #395978;
  border-left: 2px #4f4f50;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-style: solid;
  margin-left: 6px;
}

.authorDocsTable table.table tbody :hover .optionicon,
.manageTemplatesTable table.table tbody :hover .optionicon {
  opacity: 1;
}

.manageTemplatesTable table.table tbody :hover,
.authorDocsTable table.table tbody :hover {
  border-bottom-color: #0066cc;
  color: #0066cc;
  border-bottom-width: 4px;
}

.authorDocsTable table.table tbody tr.hovered-delete,
.manageTemplatesTable table.table tbody tr.hovered-delete {
  border-bottom-color: #aa0000;
}

.manageTemplatesTable table.table tr td:nth-child(1),
.authorDocsTable table.table tr td:nth-child(1) {
  background-color: #395978;
  color: white;
}

.authorDocsTable table.table tr td:nth-child(1):hover,
.manageTemplatesTable table.table tr td:nth-child(1):hover {
  background-color: white;
  color: #0066cc;
}

.authorDocsTable table.table tr th:nth-child(1),
tr td:last-child,
.manageTemplatesTable table.table tr th:nth-child(1),
tr td:last-child {
  background-color: #395978;
  width: 1rem;
}

.manageTemplatesTable input,
.authorDocsTable input {
  margin: unset;
  height: auto !important;
  font-style: italic;
  font-size: smaller !important;
  border: unset !important;
}

.manageTemplates__container {
  padding-right: 0;
  padding-left: 0;
}

.manageTemplatesTable .md-form,
.authorDocsTable .md-form {
  margin: unset;
}

.Managetemp,
.AuthDoc {
  height: 25px;
  font-size: 15px;
  margin-top: 2px;
  padding-left: 7px;
}

.searchicon img {
  height: 15px !important;
  width: 15px !important;
}

.add_document_template_svg {
  filter: invert(46%) sepia(1%) saturate(949%) hue-rotate(346deg) brightness(92%) contrast(96%);
}

.pagination li i {
  font-size: 1.3rem !important;
}

.mdb-dataTables_paginate,
.mdb-datatable-entries {
  padding: unset !important;
}

.mdb-datatable .row:nth-child(3) {
  margin-top: -10px;
  margin-right: -5px;
}

.title {
  position: fixed;
  width: calc(100% - #{$navbarWidth});
  z-index: 8;
  background-color: #0066cc;
  color: white;
  padding: 5px;
  margin-bottom: unset;
  display: flex;
  justify-content: space-between;

  svg {
    margin-right: 5px;
  }
}

.app--minimized {
  .title {
    width: calc(100% - #{$minimizedNavbarWidth});
  }
}

.title span {
  font-weight: bold;
  text-transform: uppercase;
}

.scrollable-content {
  padding-top: 48px;
}

.createTempicon {
  background-color: #fff;
  color: #6D6D6D;
  margin-top: 0;
  display: flex;
  align-items: center;
  width: 300px;
  padding: 8px 12px;

  &:hover {
    color: #0065cb;

    img {
      filter: invert(26%) sepia(98%) saturate(1688%) hue-rotate(196deg) brightness(90%) contrast(102%);
    }
  }
}

.icon_recent {
  margin-right: 5px;
  margin-top: -2px;
}

.authorDocumentTitle h5,
.manageTemplateTitle h5 {
  margin: unset;
}

.manageTemplatesTable .mdb-datatable-filter,
.authorDocsTable .mdb-datatable-filter {
  margin-left: 40px;
  margin-bottom: -8px;
  width: 16px;
  margin-top: -22px;
}

.deleteicon {
  border-bottom: 0;
}

.p2 {
  font-style: italic;
  font-weight: bold;
  margin-top: -4%;
  margin-left: 23%;
}

.btns {
  margin: 0 auto;
}

.divider {
  color: white;
}

a.new-document,
.new-template,
.newDocumentLink {
  color: #ffffff;
  display: block;
  height: auto;
  position: relative;
}

.optionicon .edit-icon path,
.optionicon .delete-icon path {
  width: 18px;
  height: 18px;
}

.optionicon .delete-icon {
  margin-left: 3px;
}

.optionicon .delete-icon:hover, .optionicon .delete-icon path:hover {
  color: white !important;
  background-color: #aa0000;
}

.optionicon .edit-icon:hover, .optionicon .edit-icon path:hover {
  color: white !important;
  background-color: #0066cc;
}

.deleteModal {
  display: flex;
  flex-direction: column;
  margin: 15% 30%;
}

.pagination .page-item.disabled .page-link,
.optionicon .delite-icon,
.pagination .page-item.disabled .page-link {
  color: rgba(0, 0, 0, 0.26);
}

.optionicon .edit-icon, .optionicon .delete-icon {
  height: 39px;
  width: 39px;
  margin-bottom: 0;
  padding: 7px;
  font-size: 1.5em;
}

.align-loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.full-page-loading {
    width: 100vw;
    height: 100vh;
  }
}

.MuiCircularProgress-colorPrimary {
  color: #007ad9 !important;
}

div.mdb-datatable div.mdb-datatable-length label,
div.mdb-datatable div.mdb-datatable-filter label {
  font-weight: lighter !important;
  text-align: left;
  margin-bottom: 0;
  font-size: 15px !important;
}

.manageTemplatesTable table.table tr th:last-child,
tr td:last-child {
  background: white;
}

.authorDocsTable table.table tr th:nth-child(1),
tr td:last-child :hover,
.manageTemplatesTable table.table tr th:nth-child(1),
tr td:last-child :hover {
  background: white;
}

.authorDocsTable table tr td:last-child,
.manageTemplatesTable table tr td:last-child {
  position: relative;
  min-width: 140px;
  border-bottom-width: 4px;
}

.dt-table-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
}

.dt-filters-container {
  display: flex;
  justify-content: space-between;
  // height: 45px;
  border-bottom: 2px solid #BAD4F1;
  border-top: 2px solid #BAD4F1;
  align-items: center;
  padding: 0 10px;
  margin-bottom: -2px;
  position: relative;
}

.dt-filter-left {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 3px 0;
  &__filter, &__search {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
    height: 100%;
  }
}

.dt-filter-left__filter {
  user-select: none;
}

.dt-search-icon {
  color: #757575;

  &.active > svg {
    fill: #06c;
  }
}

.dt-filter-left__search input {
  width: 240px;
  height: 35px;
  border-radius: 7px;
  border: 1px solid rgb(230, 230, 230);
  padding: 7px;
  margin-left: 10px;
}

.dt-checked-molecules {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;

  > div {
    background: #0066cc;
    border-radius: 21px;
    display: flex;
    align-items: center;
    color: #fff;
    padding: 0 5px;
    min-width: 55px;
    height: 18px;
    cursor: pointer;
    margin: 4px 10px 2px 0;
    border: none;
  }
}

.dt-remove-filter > svg {
  width: 13px;
  fill: #fff;
  margin-right: 2px;
}

.dt-filter-name {
  font-size: 10px;
  font-weight: 700;
  margin-right: 8px;
  margin-left: 5px;
}

.dt-project-table-tr {
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid #DCDFE2;

  > div {
    text-overflow: ellipsis;
    overflow:hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.dt-optionicon {
  visibility: hidden;
  min-width: 100%;
  height: 39px;
  border-bottom: 0;
  margin-left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;


  .dt-favorite-icon {
    visibility: visible;

    @media all and (max-width: 1440px) {
      margin-left: auto;
    }
  }

  .action-icons {
    margin-right: auto;
    max-height: 100%;
  }

  @media all and (max-width: 1440px) {
    justify-content: flex-start;
    max-width: 100%;

    .action-icons {
      margin-right: unset;
    }
  }
}

.dt-project-table__header {
  & > div,
  & > button {
    border-bottom: 4px solid #0066cc;
    display: flex;
    text-transform: uppercase;
    border-top: 2px solid #BAD4F1;
    padding: 5px 0;
    position: relative;
  }

  &.md-page {
    .dt-table-header-tr {
      div:first-child {
        display: none;
      }
    }
  }
}
.dt-table-header-tr {
  div {
    z-index: 2;
    cursor: pointer;
  }
  svg {
    z-index: 1;
  }
}
.dt-project-table__body,
.dt-project-table__header {
  & > div, & > button {
    & > div, & > button {
      width: calc(100%/6);
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      font-weight: 700;
      padding-left: 10px;
      word-break: break-word;
      justify-content: flex-start;
      text-align: left !important;
      text-transform: capitalize;
    }
  }
}

.manage-roles {
  .dt-project-table__body,
  .dt-project-table__header {
    & > div, & > button {
      & > div, & > button {
        min-width: calc(100%/6);
      }
    }
  }
}

.dt-project-table__header {
  & > div, & > button {
    & > div, & > button {
      text-transform: uppercase;
    }
  }
}

.dt-project-table-tr > div {
  justify-content: center;
}

.dt-filter-right {
  display: flex;
  justify-content: flex-start;
}

.dt-filter-right__size {
  margin-right: 25px;
  display: flex;
  justify-content: flex-start;
}

.dt-filter-right__page {
  display: flex;
  justify-content: flex-start;
}

.dt-pagintation-name {
  color: #7B7B7B;
  font-weight: 700;
  font-size: 14px;
  margin-right: 10px;
}

.dt-filter-right__count {
  position: relative;
}

.dt-page-sizes {
  position: absolute;
  z-index: 99;
  background: #fff;
  top: 25px;
  width: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #f2f2f2;

  > button {
    text-align: center;
    width: 100%;
    padding: 5px;
    transition: 0.2s;
    cursor: pointer;
    font-weight: 700;
    border: none;
    background: transparent;
    color: #0367CC;

    &:hover {
      background-color: #f2f2f2;
    }
  }
}

.checkbox-label {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    filter: grayscale(1);
  }

  label {
    margin: 0 !important;
    width: 16px;
    cursor: pointer;
  }

  .checkbox {
    width: 16px;
    min-width: 16px;
    height: 16px;
    background-image: url("../../assets/images/empty-checkbox.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  input[type=checkbox] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 16px;
    height: 16px;
  }
  input {
    &:checked {
      ~ {
        .checkbox {
          background-image: url("../../assets/images/checked-box.svg");
        }
      }
    }
  }
  span {
    margin-left: 5px;
    font-weight: bold;
    color: #7b7b7b;
  }
}

.dt-project-table-tr {
  cursor: pointer;
  border-bottom: 2px solid #DCDFE2;
  font-weight: 500;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    .option-container {
      display: flex;
    }
  }
}

.dt-project-table__body {
  & >.dt-project-table-tr {
    &:last-child {
      border-bottom: none;
    }

    & > .dt-project-table-td {
      &-options {
        display: flex !important;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        padding-right: 18px;

        @media all and (max-width: 1440px) {
          padding-right: 5px;
        }
      }
    }
  }
}

@media all and (max-width: 1680px) {
  #generated-document-table .dt-optionicon {
    .dt-edit-icon, .dt-delete-icon, .dt-edit-icon, .dt-download-icon {
      height: 30px;
      width: 30px;
      padding-left: 3px;
      padding-right: 3px;
    }
  }
}

.dt-page-sizes > span:hover {
  background-color: #f2f2f2;
}

.dt-filter-right__count, .dt-filter-right__pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #0367CC;
}

.dt-filter-right__count > span, .dt-filter-right__pagination > span {
  font-weight: 700;
  font-size: 14px;
  min-width: 50px;
  text-align: center;
  margin-right: 5px;
}

.dt-filter-right__count > svg, .dt-filter-right__pagination > svg {
  width: 14px;
  height: auto;
  cursor: pointer;
}

.dt-project-table .dt-project-table-tr:hover {
  border-bottom: 4px solid #0066cc !important;
  color: #0066cc;
}

.dt-project-table-tr:hover .dt-optionicon {
  visibility: visible;
}

.dt-edit-icon:hover, .dt-doc-icon:hover, .dt-favorite-icon:hover {
  color: #0066cc !important;
  background-color: #fff;
  cursor: pointer;
}

.dt-delete-icon:hover {
  color: #aa0000 !important;
  background-color: #fff;
  cursor: pointer;
}

.dt-bold {
  color: #000 !important;
  font-weight: 700 !important;
}

.general-label {
  font-size: 14px;
  font-weight: 700;
  color: #757575;
  margin-bottom: 15px;
}

.new-template-form label:not(.checkbox-label label) {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  display: block;
  margin-bottom: 8px !important;
  position: relative;
}

.table-main-content {
  margin-bottom: 60px;
}

.variables-wd {
  .dt-project-table-tr {
    padding: 9px 0;

    [class*="--menu-is-open"] {
      [class*="placeholder"] {
        top: 61%;
      }
    }
  }

  .selection-column {
    .item {
      width: 75%;

      @media all and (max-width: 1680px) {
        width: 90%;
      }
    }

    ::placeholder {
      font-size: 13px;
      color: #878787;
    }

    select:disabled {
      background-color: #E6E6E6;
    }

    .multiselect-field {
      .chip {
        max-height: 26px;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        margin-bottom: 5px;
        position: relative;
        z-index: 2;
      }
    }

    .autogenerated-disabled {
      &.disabled {
        input {
          display: none;
        }
      }
    }

    .autogenerated-disabled {
      background-color: #e9ecef !important;

      .react-tagsinput-tag {
        background-color: #ccc !important;
      }
    }
  }
}

.projectCard-body__title, .projectCard-body__name, .projectCard-body label {
  font-weight: 500;
}

.all-projects-container {
  .align-loading {
    min-height: calc(100vh - #{$mainHeader} - #{$smallHeader} - 48px - 40px - 95px);
  }
  .add-members {
    margin-bottom: 16px;
    border-bottom: 2px solid #E2E2E2;

    a {
      display: flex;
      align-items: center;

      span {
        font-weight: bold;
        margin-right: 5px;
        color: #0066cc;
      }

      svg {
        fill: #0066cc;
      }
    }
  }
}


.MuiDialog-container .buttons-container {
  position: absolute;
}

@media all and (max-width: 1440px) {
  .dt-project-table__body,
  .dt-project-table__header {
    & > div {
      & > div, & > button {
        font-size: 11px;
        word-break: break-word;
      }
    }
  }
}

@media all and (max-width: 991px) {
  .dt-optionicon {
    opacity: 1;
    min-width: unset;
  }

  #generated-document-table .dt-optionicon {
    .dt-edit-icon, .dt-delete-icon, .dt-edit-icon, .dt-download-icon {
      height: 25px;
      width: 25px;
    }
  }
}
.manageTemplates__container {
  height: 100%;
}

.country-multiselect {
  & ~ .select-line, & .select-line {
    &:after {
      position: absolute;
      content: url("../../assets/images/arrow.svg");
      left: 12px;
      top: 7px;
    }
  }

  & ~ .select-line {
    height: calc(100% - 27px) !important;
  }

  ul {
    li {
      input {
        min-height: unset !important;
      }
    }
  }

  &.item {
    input {
      height: 100%;
      margin-top: 0;

      &::placeholder {
        color: #808080;
        font-weight: bold;
        font-size: 14px;
      }
    }

    .more-items {
      position: absolute;
      right: 40px;
      top: -1px;
      font-size: 20px;
    }
  }
}

.project-button {
  @include btn-style($mainBlue);
}

.system-number-select {
  &.model-document-select {
    max-height: 37px !important;
    border: none;
    .select__control {
      max-height: 37px !important;
      border-radius: 10px;
    }

    .select__indicator {
      svg {
        opacity: 1;
        width: 17px;
        height: 17px;
      }
    }
  }
}
.select__value-container {
  &.select__value-container--has-value{
    overflow: unset;
  }
}
.css-2b097c-container {
  min-height: 37px;

  .select__control {
    height: auto;
    background-color: #fff;
    border: 1px solid #dce3f7;
    border-radius: 10px;
    cursor: pointer;
    color: #434343;
    padding: 0;
  }
}

.css-2b097c-container {
  min-height: 37px;

  .select__control {
    display: flex;
    height: auto;
    background-color: #fff;
    border: 1px solid #dce3f7;
    border-radius: 10px;
    cursor: pointer;
    color: #434343;
    padding: 0;
  }
}

.necDocumentFormcard {
  max-height: 1200px !important;
  max-width: inherit !important;
  border-radius: unset !important;
  border-bottom-color: transparent !important;
  box-shadow: 2px 2px 2px 3px #b0e2fa;
  border-left: unset !important;
  margin: unset !important;
  position: unset;
}

.necDocumentFormcard label {
  margin-bottom: unset;
}

.necDocumentContainer {
  max-width: inherit !important;
  height: 100%;

  .necDocumentFormcard {
    box-shadow: none !important;
  }
}

.css-14jk2my-container {
  height: auto;
  background-color: #fff;
  border: 1px solid #dce3f7;
  border-radius: 10px;
  cursor: pointer;
  color: #434343;
  padding: 0;
}


.add-btn {
  width: 28px;
  max-width: 28px !important;
  min-width: unset !important;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0065cb !important;
  color: #fff!important;
  font-size: 20px;
  font-weight: bold;
  padding: 0;
  border: 0;
  border-radius: 50% !important;
  margin-left: 0 !important;
  margin-top: 21px !important;
  margin-right: 10px !important;
}

.remove-btn {
  width: 28px;
  height: 28px;
  max-width: 28px !important;
  min-width: unset !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e44939 !important;
  color: #fff !important;
  font-size: 20px;
  font-weight: bold;
  padding: 0;
  border: 0;
  border-radius: 50%  !important;
  margin-left: 0 !important;
  margin-top: 21px !important;
  margin-right: 10px !important;

  svg {
    max-width: 9px;
    width: 9px;
  }
}

// Modals

.create-internal-report-modal {
  .create-modal-body {
    min-height: 400px;
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .body-content {
      width: 100%;
    }

    .item-header-row {
      display: flex;
    }

    .header-item {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 13px;
      display: flex;
      align-items: center;
      color: #878787;
      margin-right: 7px;
      margin-bottom: 4px;
      width: 100%;

      &.ml-20 {
        margin-left: 24px;
        width: 150px;
      }
    }
  }

  .create-modal-info {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #757575;
    margin-bottom: 24px;

    svg {
      fill: #757575;
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
  }

  .item-body-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;

    .row-item {
      margin-right: 8px;

      input {
        background: #FFFFFF;
        border: 1px solid #878787;
        box-sizing: border-box;
        border-radius: 5px;
        height: 30px;
        width: 100%;

        &.error-field {
          border-color: #FF0000;
        }
      }
    }

    .row-item:nth-child(2) {
      width: 150px;
    }

    .row-item:nth-child(3) {
      width: 100%;
    }

    .count-buttons-container {
      width: 21px;
      height: 21px;

      svg {
        border-radius: 50%;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #fff;
        fill: #0066CC;
        width: 100%;
        height: 100%;
        font-size: 24px;

        &.delete {
          fill: #D67676;
        }
      }
    }
  }

  .create-buttons-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.error-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #FF0000;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  margin-left: 24px;

  svg {
    fill: #FF0000;
    height: 16px;
    width: 15px;
    margin-right: 6px;
  }
}

.btnAddNewReport {
  border-radius: 4px !important;
  font-size: 12px !important;
  line-height: 14px !important;
  background-color: #0066CC !important;
  font-weight: lighter !important;
  color: #fff !important;
  height: 30px !important;
  border-color: #0066cc !important;
  padding: 5px 41px !important;
}

// delete project modal

.project-deleteModal {
  @include modal();

  .modal-header {
    svg {
      fill: #fff;
    }
  }

  &.variable-status-modal {
    min-width: 400px;

    .choice-container {
      width: 270px;
    }

    .warning-container {
      span {
        color: $warning !important;
      }
    }
  }

  &.variable-details-modal {
    min-width: 90%;

    .details-content {
      .detail-item {
        margin-bottom: 13px;

        &:last-child {
          margin-bottom: 0;
        }

        .detail-title {
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;
          color: $mainBlue;
          margin-bottom: 5px;
        }

        .detail-box {
          display: flex;
          flex-wrap: wrap;
          padding: 16px 8px 0;
          background: #F5FAFD;
          border: 1px solid $mainBlue;
          border-radius: 5px;

          .item {
            flex: 0 0 25%;
            max-width: 25%;
            padding-inline: 12px;
            margin-bottom: 22px;
            word-wrap: break-word;

            span {
              display: block;
            }

            .caption {
              font-weight: 700;
              font-size: 11px;
              line-height: 13px;
              color: $paragraphGray;
              margin-bottom: 16px;
            }

            .value {
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              color: $label;
              margin-left: 11px;
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.MuiDialog-paperWidthSm:has(.variable-details-modal) {
  width: 90%;
  max-width: 1230px;
}

.MuiDialog-paperWidthSm:has(.template-addVariableModal) {
  max-width: 1000px;
  max-height: unset;
}

.project-line {
  padding: 10px;
  display: flex;
  border-bottom: 1px solid #C4C4C4;
  width: 100%;
  align-content: space-around;
  justify-content: space-between;
  flex-direction: row;
  cursor: pointer;

  span {
    font-weight: 600;
    color: #000000;
  }
}

.section-info {
  display: flex;
  align-items: center;
  color: #757575;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 8px;

  svg {
    fill: #878787;
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
}

.react-datepicker__input-container {
  input {
    width: 100%;
    height: 37px;
    font-weight: 500;
    font-size: 11px;
    margin-right: 25px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #dce3f7;
    border-radius: 10px;
    outline: 0;
  }
}
.css-yk16xz-control {
  border: 1px solid #dce3f7 !important;
  border-radius: 10px !important;
  min-height: 37px !important;
}
.css-1pahdxg-control {
  border: 1px solid #dce3f7 !important;
  border-radius: 10px !important;
  min-height: 37px !important;
}
.css-1g6gooi {
  max-height: 37px !important;
  margin: 0;
}
.css-1hwfws3 {
  min-height: 37px !important;
}
.css-xb97g8 {
  svg {
    opacity: 1 !important;
  }
}

.transferred-modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  div {
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }

  font-size: 16px;
  font-weight: bold;
  color: #000;

  svg {
    max-width: 50px;
    width: 50px;
  }
}

.transferred-modal {
  max-width: 800px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;

  .modal-header {
    background-color: #0066CC;
    min-height: 48px;
    width: 100%;
    position: relative;
    padding: 0;
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: unset;
    .close-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 48px;
      height: 47px;
      background-color: #0052A3;
      cursor: pointer;
    }
    span {
      text-transform: uppercase;
      color: #fff;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 17px;
      margin-left: 9px;
    }
    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      svg {
        cursor: pointer;
      }
    }
  }

  .MuiDialog-container {
    min-width: 100%;
    margin: 0 auto;

    .MuiDialog-paper {
      max-width: 100%;
      min-width: 100%;
      margin: 0;
      border-radius: 0;
    }
  }

  .modal-body {
    padding: 1rem;
  }

  &.add-members-modal {
    max-width: 650px;

    .modal-body {
      padding: 20px !important;

      .label-text {
        color: #757575;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .add-member-box {
        position: relative;

        .search-icon {
          position: absolute;
          top: 7px;
          left: 51px;
        }

        input {
          padding-left: 32px;

          &::placeholder {
            color: #878787;
            font-size: 13px;
          }
        }

        .vector-pluse {
          position: absolute;
          top: 7px;
          right: 10px;

          svg {
            fill: #37A710;
          }
        }
      }

      .members-box {
        min-height: 250px;

        .assign-user {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          .user {
            flex-basis: unset;
            max-width: unset;

            .member-img {
              width: 42px;
              height: 42px;
              max-width: 42px;
              border-radius: 50%;
              background-color: rgba(176,226,250,.37);
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                max-width: 100%;
                border-radius: 50%;
              }
            }

            .member-name {
              font-size: 14px;
              color: #000;
              font-weight: bold;
            }
          }

          .user-role {
            flex-basis: unset;
            max-width: unset;
            display: flex;
            align-items: center;
            min-width: 400px;
            margin-left: auto;

            .select-content {
              margin-right: 7px;
              width: calc(100% - 15px - 7px);
            }
          }
        }
      }
    }
  }
}

.transferred-modal-fail {
  .modal-header {
    background: #dc3545;
  }
}

.transfer-modal .modal-header {
  background-color: #0066cc;
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
  }
}

.transferred-modal {
  .modal-header {
    .close {
      opacity: 1;

      &:hover {
        opacity: 1;
      }

      span {
        color: #fff;
        font-weight: bold;
        opacity: 1;
      }
    }

    h5 {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.transfer-modal {
  .transferred-modal-content {
    display: block !important;
  }

  .document-name, .text {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .document-name span {
    color: #06c;
    margin-left: 5px;
  }

  .pair {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-bottom: 2px solid #ccc;
    color: #000;
    font-weight: bold;
    text-transform: uppercase;

    > .key {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .d-flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .pair {
    .value, .key span {
      color: #06c;
      margin-left: 10px;
    }
  }

  .sub-text {
    font-size: 14px;
    color: #999;
    font-weight: bold;
    margin: 15px 0;
  }

  .change {
    display: flex;
    justify-content: flex-end;
    margin: 40px 0;

    a {
      color: #06c;
      text-decoration: underline;
    }
  }

  .action {
    display: flex;
    justify-content: flex-end;
  }
}

// form
.form-input {
  margin: 0 auto;
  height: 500px;
  width: 500px;
}

.form-inside-input {
  height: 500px;
  width: 200px;
  margin: 0 auto;
}

.description {
  width: 1010px;
  margin-left: 20px;
  margin-bottom: -17px;
  margin-top: -10px;
}

.authselect {
  width: 1010px;
  margin-left: 20px;
}

.text-input,
.text-input1 {
  border: 1px solid rgb(220, 227, 247);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  outline: 0;
}

.authselect {
  border: 1px solid rgb(220, 227, 247);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  outline: 0;
  margin-left: 1px;
}

.select {
  border: 1px solid rgb(220, 227, 247);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  outline: 0;
  width: 1px;
  height: 20px;

  /* border-right: 10px solid blue;
 border-right-style: unset;
 left: 50%; */

  &.public {
    display: none !important;
  }
}

.Authorselect :hover {
  border-right: 2px blue;
}

.templatename {
  border: 1px solid rgb(220, 227, 247);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  outline: 0;

  /* margin-left: -1px; */
}

.inputresponse {
  color: red;
  font-size: 11px;
  margin-top: 5px;
}

.react-tagsinput {
  &.empty {
    height: 37px;

    span {
      display: block;
      position: relative;
      top: -5px;
    }
  }
}

.action-list {
  svg {
    width: 20px;
  }

  padding-right: 7px;
}

.form-content {
  padding-bottom: 60px;
}

.form-content-linear-regression {
  padding-bottom: 0;
}

.working-document-form {
  padding-bottom: 60px;
  [class*="--has-value"] {
    //[class*="-singleValue"] {
    //   top: 61%;
    //}

    [class*="--is-disabled"] {
      top: 50%;
    }
  }
}

.dt-project-table__body {
  .collapsable-content {
    .content {
      padding: 0;
      > div {
        > div {
          justify-content: flex-start;
          padding-left: 10px;
          font-weight: 700;
          word-break: break-word;
          text-align: left !important;
          text-transform: capitalize;
        }
      }
    }
  }
}

.form-group, .item {
  &.changed {
    & > input, .select__control {
      border: 1px solid #0066cc !important;
      box-shadow: 0 4px 4px 1px rgba(0, 102, 204, 0.12) !important;
    }

    .cancel-link {
      font-size: 11px;
      display: inline-block;
      padding-left: 5px;
      margin-top: 5px;
      color: #0066cc;
    }

    .select-line {
      bottom: 22px
    }
  }
}

.variables-content {
  .dt-project-table-tr, .dt-table-header-tr .th {
    cursor: default;
  }
  .dt-project-table__body, .dt-table-header-tr {
    &.more-columns {
      .dt-project-table-tr > div, .th {
        width: 20% !important;

        @media all and (max-width: 1680px) {
          width: calc(74%/3) !important;

          &:nth-child(4), &:nth-child(5) {
            width: 13% !important;
          }
        }
      }
    }
  }

  .no-data-text {
    display: block;

    i {
      color: #0066CC;
    }
  }

  .dt-project-table-tr {
    & > div {
      text-overflow: unset;
      -webkit-line-clamp: unset;
      -webkit-box-orient: unset;
      white-space: unset;
      overflow: unset;
    }
  }

  .pending {
    color: $warning;
  }

  .approved {
    color: $mainBlue;
  }

  .deleted {
    color: $error;
  }

  .dt-project-table-tr {
    .options {
      opacity: 0;

      svg {
        margin-right: 10px;
      }
    }

    &:hover {
      .options {
        opacity: 1;
      }
    }
  }
}

.select__placeholder {
  max-width: calc(100% - 8px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.internal-report-data {
  flex: 0 0 100%;
  max-width: 100%;

  .none-type-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .additional-product {
    &.remove {
      svg {
        fill: #E44939;
      }
    }
  }
  .icon-item {
    max-width: 30px !important;
    flex-basis: 30px !important;
    margin-bottom: 0 !important;

    svg {
      fill: #0066cc;
    }
  }
}

.field-item {
  flex: 0 0 40%;
  max-width: 500px;
  margin-right: 15px;
  margin-bottom: 25px;
  &.small-size {
    flex: 0 0 10% !important;
    max-width: 150px !important;
  }
  label {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
    display: block;
    margin-bottom: 9px;
    position: relative;
    padding-left: 0;
  }
}

.btnCreateNewDocumnet {
  background-color: #0066cc;
  color: white;
  width: 190px;
  padding: 12px;
  display: flex;
}

.btnCancelNewDocument {
  background-color: white !important;
  font-weight: lighter !important;
  height: 35px !important;
  width: 102px !important;
  border: 1px solid #686D71 !important;
  border-radius: 3px;

  & > .MuiButton-label {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    color: #686D71;
  }
}

.btnSaveNewDocument {
  height: 35px !important;
  width: 102px !important;
  background-color: #0066cc !important;
  border-color: #0066cc !important;

  & > .MuiButton-label {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    color: white;
  }
}

.btnPublishDocument {
  height: 30px !important;
  width: 102px !important;
  background-color: #0aa987 !important;
  border-color: #0aa987 !important;

  & > .MuiButton-label {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    color: white;
  }
}

.btnWithdrawDocument {
  height: 30px !important;
  width: 102px !important;
  background-color: transparent !important;
  border: 1px solid #F4A261 !important;

  & > .MuiButton-label {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    color: #F4A261;
  }

  &:hover {
    background-color: #F4A261 !important;

    &>.MuiButton-label {
      color: white;
    }
  }
}
.btnSaveDocument {
  height: 30px !important;
  width: 102px !important;
  background-color: transparent !important;
  border: 1px solid #F4A261 !important;

  & > .MuiButton-label {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    color: #F4A261;
  }

  &:hover {
    background-color: #F4A261 !important;

    &>.MuiButton-label {
      color: white;
    }
  }
}

.form-content {
  padding: 14px 25px;
  display: flex;
  flex-direction: column;

  .general-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    .is-default {
      display: flex;
      align-items: flex-end;
      padding-bottom: 4px;
      margin-left: 20px;
      cursor: pointer;
    }
  }

  .item-label {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    color: #4F4F4F;
    min-width: 180px;
    padding: 0;
  }

  .item {
    display: flex;
    flex-direction: column;
    position: relative;

    &.main-item {
      width: 210px;
    }

    .select {
      min-width: 100%;
      height: 37px;
      padding-right: 7px;
    }
  }

  .metadat-container {
    margin-top: 27px;
  }
}

.base-template-content {
  .dt-table-header-tr {
    .th {
      min-width: calc((100% / 7) + (88px / 6));
    }
  }

  .dt-project-table-tr {
    min-height: 39px;

    > div {
      min-width: calc((100% / 7) + (88px / 6));
    }
  }

  .dt-table-header-tr, .dt-project-table-tr {
    & > div, & > .th {
      &.options {
        min-width: 88px;
      }
    }
  }
}

.variables-wd {
  .base-template-content {
    .dt-table-header-tr {
      .th {
        min-width: unset;
        width: 25%;
      }
    }

    .dt-project-table-tr {

      > div {
        min-width: unset;
        width: 25%;
      }
    }
  }
}

.variables-content {
  margin-top: 60px;

  .section-info {
    border-bottom: none !important;
    align-items: start !important;
  }

  .info-text {
    font-size: 11px;
    text-transform: unset;
  }

  .setting-label {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 12px;
    border-bottom: 1px solid #B0E2FA;
    color: #757575;
  }

  .dt-project-table-tr {
    > div {
      &:first-child {
        flex-direction: row;
      }
      > span {
        margin-left: 3px;
        text-transform: lowercase;
        font-size: 11px;
        color: #4F4F4F;
        font-weight: 400;

        strong {
          color: red;
        }
      }
    }
  }

  .grouping-title {
    padding: 9px 10px;
    background-color: rgba(217,217,217,.3);
    color: #0066CC;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid #0066CC;

    svg {
      width: 14px;
      margin-left: 5px;
    }
  }

  .grouping-row {
    > div:first-child {
      padding-left: 20px;
    }
  }

  .manual-variables-content {
    .selection-column {
      .autogenerated-disabled {
        background-color: #fff !important;

        &.disabled {
          background-color: #e9ecef !important;

          input {
            display: none;
          }
        }
        &:has(input:disabled) {
          background-color: #e9ecef !important;
        }
      }
    }
  }
}

.newTemplate__container {
  padding-left: 0;
  padding-right: 0;
  .AuthDoc {
    display: flex;
    align-items: center;
  }
  .card-body {
    form {
      input {
        min-height: 37px;
      }
      select {
        min-height: 37px;
      }
    }
  }

  .form-content {
    .countries {
      .css-b8ldur-Input {
        max-height: 21px;

        .select__input {
          max-height: 21px;

          input {
            max-height: 21px;
          }
        }
      }

      .select__indicator {
        svg {
          width: 16px;
          height: 16px;
          opacity: 1;
        }
      }

      .css-1pahdxg-control {
        box-shadow: none;
        border-color: transparent;

        .css-1wa3eu0-placeholder {
          margin-top: 5px;
        }

        .css-g1d714-ValueContainer {
          padding-top: 15px;
        }

        &[class*="focused"] {
          min-height: 37px;
        }
      }

      .select__control {
        &:hover {
          border-color: #dce3f7;
        }

        &[class*="focused"] {
          border-color: #dce3f7;
          box-shadow: none;
        }
      }
    }

    .countries-item {
      .select-line {
        height: calc(100% - 35px);

        &:after {
          position: absolute;
          content: url("../../assets/images/arrow.svg");
          left: 12px;
          top: 7px;
        }
      }
    }

    .country-multiselect:not(.item) {
      cursor: pointer;
      position: relative;
      z-index: 7;
    }

    .country-multiselect {
      &.item {
        background-image: url("../../assets/images/arrow.svg");
        background-size: 10px;
        background-position: 97% 16px;
        background-repeat: no-repeat;

        .select-line {
          &:after {
            content: none;
          }
        }
      }
    }
  }

  .card {
    box-shadow: none !important;
  }
}

//::-webkit-scrollbar-thumb {
//  height: 6px;
//  width: 6px;
//}
//
//::-webkit-scrollbar-track, ::-webkit-scrollbar {
//  width: 6px;
//}
//
//::-webkit-scrollbar-thumb {
//  background-color: #0066cc;
//}

.modal-body {
  .buttons-container {
    border: none;
  }
}
.buttons-container {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 7;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 12px 18px;
  background: #fff;
  border-top: 1px solid rgba(0, 102, 204, 0.2);

  &.not-fixed {
    position: unset;
    justify-content: flex-end;
    width: 100%;
  }
}

.newProjectContent {
  select {
    //-webkit-appearance: none;
    //-moz-appearance: none;
    //appearance: none;
    background-image: url(/src/images/down_arrow.png);
    background-position: 95%;
    background-repeat: no-repeat;
    padding-right: 30px;

    &::-ms-expand {
      display: none;
    }
  }

  .react-tagsinput--focused {
    border-color: #ccc;
  }

  .select-line {
    position: absolute;
    width: 4px;
    height: 36px;
    right: 25px;
    bottom: 0;
    background-color: #0065cb;
    z-index: 5;

    //&:after {
    //   position: absolute;
    //   content: url("../../assets/images/arrow.svg");
    //   left: 10px;
    //   top: 7px;
    //}
  }

  .blue-border {
    height: 6px;
    background-color: #06c;
  }

  .filter-box {
    border-bottom: 2px solid #06c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;
    margin-bottom: 10px;
  }

  #project-color {
    border: 1px solid #E6E6E6;
    border-radius: 50%;
    background-color: #fff;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
  }

  .form-group {
    position: relative;

    .info {
      font-size: 12px;
      margin-top: 10px;
      font-weight: bold;
      color: #555;

      svg {
        margin-right: 5px;
      }
    }
    //select {
    //   appearance: none;
    //   /* for Firefox */
    //   -moz-appearance: none;
    //   /* for Chrome */
    //   -webkit-appearance: none;
    //}

    /* For IE10 */
    select::-ms-expand {
      display: none;
    }

    .form-control {
      &.validation-error {
        border: 1px solid #E51414;
      }
    }

    &.short {
      max-width: 225px;
    }

    label {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 700;
      display: block;
      margin-bottom: 9px;
      position: relative;
      padding-left: 0;

      .mandatory {
        color: #E51414;
        font-weight: bold;
        margin-left: 3px;
      }
    }

    .system-number-select {
      .select__indicators {
        .select__clear-indicator {
          svg {
            display: none;
          }
        }

        .select__dropdown-indicator {
          padding: 8px 5px 8px 10px;

          svg {
            width: 15px;
            opacity: 1;
          }
        }
      }

      &.required {
        & > div {
          border-color: red;
        }
      }
    }
  }
  .multiselect-container {
    .search-wrapper {
      display: flex;
      flex-wrap: wrap;

      input {
        order: 1;
        width: 100%;
        display: inline-block;
      }

      .icon_down_dir {
        top: 18px;
      }

      .chip {
        padding: 5px 10px;
        background: #e6e6e6;
        border-radius: 2px;
        color: #4f4f4f;
        order: 2;

        i {
          margin-left: 10px;

          svg {
            width: 10px;
            height: 10px;

            path {
              fill: #878787;
            }
          }
        }
      }
    }

    .optionContainer {
      .option {
        display: flex;
        align-items: center;
        background-color: transparent;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        border-bottom: 1px solid #b0e2fa;
        color: #4f4f4f;

        &:hover {
          background-color: transparent;
          color: #4f4f4f;
        }

        &.selected {
          color: #0066cc;
        }
      }
    }

    &.filled {
      .search-wrapper {
        input {
          margin-bottom: 10px;
        }
      }
    }

    &.required {
      .search-wrapper {
        border-color: red !important;
      }
    }

    .optionListContainer {
      z-index: 999;
    }
  }
}

.select-line {
  position: absolute;
  width: 4px;
  height: 36px;
  right: 25px;
  bottom: 0;
  background-color: #0065cb;
  z-index: 5;
}

.formBlock {
  .right-content {
    margin-bottom: 50px;

    .countries {
      border-bottom: 1px solid #B0E2FA;
      margin-bottom: 25px;

      .field-item {
        flex: 0 0 40%;
        max-width: 500px;
        margin-bottom: 25px;
      }
    }

    .listItem {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      .field-item {
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }

        &.middle-size {
          flex: 0 0 31%;
          max-width: 31%;
        }

        &.big-size {
          flex: 0 0 35%;
          max-width: 35%;
        }

        &.small-size {
          flex: 0 0 15%;
          max-width: 15%;

          .react-tagsinput {
            //max-height: 37px;
            //min-height: 37px;

            input {
              min-height: 100%;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .products-block {
      border-bottom: 1px solid #B0E2FA;
      padding-bottom: 25px;
      margin-bottom: 25px;
    }
  }

  .internal-report-data {
    flex: 0 0 100%;
    max-width: 100%;

    .none-type-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-left: 50px;

      @media all and (max-width: 1400px) {
        padding-left: 20px;
      }

      .loading-image {
        position: absolute;
        top: 30px;
        left: 3px;

        & ~ select {
          padding-left: 30px;
        }
      }

      .small-size {
        flex: 0 0 10% !important;;
        max-width: 150px !important;

        @media all and (max-width: 1680px) {
          max-width: calc(17% - 45px) !important;
          flex-basis: calc(17% - 45px) !important;
        }

        @media all and (max-width: 1300px) {
          max-width: calc(30% - 45px) !important;
          flex-basis: calc(30% - 45px) !important;
        }
      }

      .icon-item {
        max-width: 30px !important;
        flex-basis: 30px !important;
        margin-bottom: 0 !important;

        svg {
          fill: #0066cc;
        }
      }
    }

    .additional-product {
      &.remove {
        svg {
          fill: #E44939;
        }
      }

      &:disabled {
        svg {
          fill: #878787;
        }
      }
    }
  }

  .left-content {
    padding: 0 15px;

    .generic-container {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 2;

      .row-item {
        display: flex;
        justify-content: flex-start;
        width: 100%;
      }

      .field-item {
        flex: 0 0 40%;
        max-width: 500px;
        margin-right: 60px;
        margin-bottom: 25px;

        .form-group {
          label {
            padding-left: 0;
          }
        }

        &.icon-field {
          max-width: 30px;
          flex-basis: 30px;
          margin-bottom: 0;

          svg {
            transform: rotate(45deg);
            fill: #E44939;
          }
        }
      }
    }

    .added-users-content {
      display: flex;
      align-items: center;

      .members {
        display: flex;
        align-items: center;

        .item {
          width: 46px;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #EFF9FA;
          color: #4F4F4F;
          font-weight: bold;
          border: 1px solid #fff;
          border-radius: 50%;
          margin-left: -15px;

          &:first-child {
            margin-left: unset;
          }
        }
      }

      .users-count {
        font-size: 16px;
        font-weight: bold;
        color: #000;

        span {
          color: #0066CC;
        }
      }

      .users-count {
        margin-left: 15px            ;
      }
    }

    .ro-number-content {
      margin-bottom: 25px;
      border-bottom: 1px solid #B0E2FA;

      .generic-container {
        display: flex;
        align-items: center;

        .field-item {
          margin-right: 15px;

          input {
            &::placeholder {
              color: #C4C4C4;
            }
          }

          &.small {
            max-width: 240px;
            min-width: 240px;

            .icon {
              position: absolute;
              top: 34px;
              left: 10px;

              svg {
                fill: #C4C4C4;
              }

              & ~ input {
                padding-left: 32px;
              }
            }

            &.product-family-select {
              max-width: 340px;
              min-width: 340px;
            }
          }

          &.big {
            max-width: 300px;
            min-width: 300px;
          }

          .form-group {
            position: relative;

            .search-icon {
              position: absolute;
              top: 35px;
              left: 9px;
              fill: #C4C4C4;
            }

            .search-input {
              padding-left: 32px;
            }
          }
        }
      }

      &.none-type {
        border-bottom: none;


      }
    }

    .users-color-content {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid #B0E2FA;

      & > div {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .add-members {
        border-bottom: none;
      }
    }
  }

  .add-additional-ro {
    padding-top: 15px;
    border-top: 1px solid #B0E2FA;

    .add-ro-numbers {
      display: flex;
      align-items: center;
      border: none;
      background-color: transparent;
      padding: 0;
      box-shadow: none;

      span {
        text-transform: uppercase;
        font-weight: bold;
        color: #0066CC;
        margin-right: 12px;
      }

      svg {
        fill: #0066CC;
      }

      &:hover, &:focus {
        box-shadow: none;
        border: none;
        outline: none;
      }

      &:disabled {
        span {
          color: #878787;
        }

        svg {
          fill: #878787;
        }
      }
    }
  }

  .open-project-metadata {
    display: flex;
    align-items: center;
    background-color: #0066CC;
    color: #fff;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 6px;
    max-height: 35px;

    svg {
      margin-right: 14px;
      fill: #fff;
    }
  }

  .separator-line {
    padding-top: 15px;
    border-top: 2px solid #0066CC;
  }

  .section-info {
    display: flex;
    align-items: center;
    color: #757575;
    margin-bottom: 15px;

    svg {
      fill: #878787;
      margin-right: 5px;
    }
  }

  .ro-number-content {
    .field-item {
      max-width: 210px;
    }
  }

  .odg-content {
    margin-bottom: 25px;
    border-bottom: 1px solid #B0E2FA;

    .generic-container {
      .field-item {
        max-width: 210px;
      }
    }
  }
}

#newDocumentForm {
  .form-content {
    .item {
      label {
        margin-bottom: 10px;
        text-transform: uppercase;
        color: #878787;
        font-weight: bold;
        font-size: 11px;
      }
    }
  }

  .section-info {
    display: flex;
    align-items: center;
    color: #757575;
    margin-bottom: 15px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e2e2e2;

    svg {
      fill: #878787;
      margin-right: 5px;
      width: 20px;
      height: 20px;
    }

    .open-doc-btn {
      display: flex;
      align-items: center;
      color: #0066cc;
      font-weight: 600;

      svg {
        fill: #0066CC;
      }

      &:disabled {
        color: #878787;

        svg {
          fill: #878787;
        }
      }
    }
  }

  #rocheNumber {
    max-height: 37px;
  }
}

.mandatory {
  color: #e51414;
  font-weight: bold;
  margin-left: 3px;
}

.error-message {
  font-size: 13px;
  font-weight: 500;
  color: red;
  display: block;
  padding: 5px 0;
}

.multiselect-container {
  .search-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-right: 55px;

    input {
      order: 1;
      width: 100%;
      display: inline-block;
    }

    .icon_down_dir {
      top: 18px;
    }

    .chip {
      padding: 5px 10px;
      background: #e6e6e6;
      border-radius: 2px;
      color: #4f4f4f;
      order: 2;

      i {
        margin-left: 10px;

        svg {
          width: 10px;
          height: 10px;

          path {
            fill: #878787;
          }
        }
      }
    }
  }

  .optionContainer {
    .option {
      display: flex;
      align-items: center;
      background-color: transparent;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: 1px solid #80bdff;
      color: #4f4f4f;

      &:hover {
        background-color: transparent;
        color: #4f4f4f;
      }

      &.selected {
        color: #0066cc;
      }
    }
  }

  &.filled {
    .search-wrapper {
      input {
        margin-bottom: 10px;
      }
    }
  }

  &.required {
    .search-wrapper {
      border-color: red !important;
    }
  }
}

.checkbox-label {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;

  .checkbox {
    width: 16px;
    min-width: 16px;
    height: 16px;
    background-image: url("../images/empty-checkbox.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  input[type=checkbox] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 16px;
    height: 16px;
  }
  input {
    &:checked {
      ~ {
        .checkbox {
          background-image: url("../images/checked-box.svg");
        }
      }
    }
  }
  span {
    margin-left: 5px;
    font-weight: bold;
    color: #7b7b7b;
  }
}

// Admin
.administration-overview {
  width: 100%;

  .MuiTab-wrapper {
    flex-direction: row;

    svg {
      margin-right: 5px;
    }
  }

  .MuiTabs-root {
    margin-bottom: 15px;
  }
}

.table-options {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 45px;
  border-bottom: 2px solid #BAD4F1;
  padding: 0 10px;
}

.option-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.admin-search {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
  height: 100%;

  > img {
    position: absolute;
  }
}

.option-item {
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.admin-search > input {
  width: 240px;
  height: 35px;
  border-radius: 7px;
  border: 1px solid rgb(230, 230, 230);
  padding: 7px;
  margin-left: 10px;
}

.input-image-container {
  display: flex;
  width: 40px;
  height: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;

  > svg {
    fill: #4C6075;
  }
}

.admin-search > input::placeholder {
  color: #4C6075;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  left: -18px;
}

.option-right {
  display: flex;
  padding: 0 15px;
  height: 100%;
  align-items: center;

  > {
    div {
      height: 100%;
      display: flex;
      align-items: center;
    }

    span {
      color: #fff;
      margin-right: 10px;
    }

    div > svg {
      cursor: pointer;
      fill: #fff;
      height: 20px;
    }
  }
}

.list-count {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
  background-color: #fff;
  border-radius: 8px;
  width: 50px;
  color: #497098;
  font-weight: bold;
}

.option-item {
  img {
    height: 20px;
    transform: scaleX(-1);
  }

  svg {
    color: #757575;
    fill: #757575;
  }
}

.table-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.table-header {
  border-bottom: 4px solid #0066cc;
  display: flex;
  padding: 5px 0;
}

.manage-roles .table-body {
  & > div {
    & > div, & > a {
      width: calc(100%/6);
      display: flex;
      align-items: center;
      font-weight: 700;
      padding-left: 10px;
    }
  }
}

.table-header > div {
  width: calc(100%/6);
  display: flex;
  align-items: center;
  font-weight: 700;
  padding-left: 10px;
}

.table-body {
  display: flex;
  flex-direction: column;
}

div {
  &.user-body-tr {
    border-bottom: 2px solid #E1E1E1;
    display: flex;
  }

  &.option {
    min-width: 270px;
  }
}

.user-body-tr div.option {
  border-left: 2px solid #E1E1E1 !important;
}

div.user-body-tr:hover {
  border-bottom: 4px solid #0066cc;
}

//.table-body {
//  &::-webkit-scrollbar {
//    width: 10px;
//  }
//
//  &::-webkit-scrollbar-track {
//    background-color: #475666;
//  }
//
//  &::-webkit-scrollbar-thumb {
//    background-color: #6D93BA;
//  }
//}

.status-color {
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
  margin-right: 7px;
}

.user-status__container {
  display: flex;
  align-items: center;
}

.table-footer {
  display: flex;
  justify-content: flex-start;
  height: 25px;
  background-color: #497098;
  color: #fff;
  font-size: 11px;

  > div {
    border-right: 1px solid #3B5F84;
    padding: 0 5px;
    display: flex;
    align-items: center;
  }
}

.option {
  > div {
    margin-left: -10px;
    display: none;
  }

  img {
    width: 44px;
    background-color: #ebebeb;

    &:hover {
      background: #497098;
    }
  }
}

.user-body-tr:hover .option-icons__container {
  display: flex;
}

ul.status_box {
  grid-template-columns: repeat(2, 100px);
}

.user-image {
  display: flex;
  justify-content: flex-start;
  min-height: 44px;
  align-items: center;

  > {
    span:first-child, img {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      background: #e9f2fb;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}

.overview-modal-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #7779;
}

.overview-modal {
  background: #fff;
  min-width: 360px;
  width: 30%;
  border-radius: 10px;
}

.overview-modal-header {
  height: 50px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: #60832F;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.invite-icon {
  display: flex;
  align-items: center;

  > {
    span {
      margin-left: 10px;
    }

    svg {
      transform: rotateY(180deg);
    }
  }
}

.overview-modal-header > svg {
  cursor: pointer;
}

.overview-modal-footer {
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    width: 100px;
    height: 30px;
    border-radius: 7px;
    border: 2px solid #60832F;
  }
}

.close-modal {
  color: #60832F;
}

.invite-modal {
  color: #fff;
  background-color: #60832F;
}

.overview-modal-body {
  padding: 10px;
}

.select-item {
  display: flex;
  justify-content: flex-start;
  height: 40px;
  align-items: center;

  > label {
    color: #6D6D6D;
    margin-right: 7px;
  }
}

.select-input-container {
  flex-grow: 1;
  position: relative;

  > input {
    width: 100%;
    border-bottom: 2px solid #60832F;
    color: #60832F;
    text-align: center;
    cursor: pointer;
  }
}

.arrow-container {
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}

.select-arrow {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #60832F;
}

.description-container {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  margin-bottom: 60px;

  > textarea {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 80px;
    border: 2px solid #60832F;
    border-radius: 7px;
    resize: none;
    color: #60832F;
    padding: 10px;
  }
}

.select-option-container {
  position: absolute;
  width: 100%;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 0 4px 0 rgba(0, 101, 203, 0.21);

  &.closed {
    height: 0;
    overflow: hidden;
  }
}

.select-option {
  padding: 10px;
  transition: 0.3s;
  cursor: pointer;
  border-bottom: 1px solid #f5f5f5;

  &:hover {
    background-color: #f5f5f5;
  }
}

.search-container {
  position: absolute;
  top: -5px;
  height: 100%;
  right: 10px;

  > svg {
    fill: #60832F;
  }
}

.users-info {
  color: #8390A2;
  font-weight: 700;
}

.mar-15px {
  margin-right: 15px;
}

.rotate-180 {
  transform: scaleX(-1);
}

.user-delete-icon, .user-edit-icon {
  height: 44px !important;
  width: 44px !important;
  margin-bottom: 0;
  padding: 7px !important;
  font-size: 1.5em !important;
  color: rgba(0, 0, 0, 0.26) !important;
  cursor: pointer;
}

.user-delete-icon:hover {
  color: white !important;
  background-color: #aa0000;
}

.user-edit-icon:hover {
  color: white !important;
  background-color: #0066cc;
}

.create-user, .manage-roles, .admin-filter > img {
  cursor: pointer;
}

.change-link {
  margin-left: 10px;
  color: #007ad9;

  &.d-none {
    display: none;
  }
}

.site-role {
  > div.item {
    width: 100%;
    position: relative;
  }

  select {
    height: 37px;
    width: 100%;
  }
}

#adminOverview {
  .table-body {
    & > div > div {
      & > div, & > a {
        width: calc(100%/6);
        display: flex;
        align-items: center;
        font-weight: 700;
        padding-left: 10px;

        @media all and (max-width: 1300px) {
          width: 25%;

          &:last-child {
            min-width: 270px;
          }
        }
      }
    }
  }

  .table-header {
    div {
      @media all and (max-width: 1300px) {
        width: 25%;
      }
    }
  }
}

.action-item.active {
  p {
    color: #0066CC !important;
  }

  svg {
    fill: #0066CC !important;
  }
}

.react-tagsinput {
  background-color: #fff;
  border: 1px solid #dce3f7;
  border-radius: 10px;
  cursor: pointer;
  color: #434343;

  input {
    width: 100%;
  }
}

.filled, .disabled {
  .react-tagsinput input,
  .react-tagsinput-remove {
    display: none;
  }
}

.react-tagsinput {
  background-color: #fff !important;
  border: 1px solid #dce3f7 !important;
  border-radius: 10px;
  cursor: pointer;
  color: #434343;

  input {
    width: 100%;
  }
}

.react-tagsinput-tag {
  background-color: #E6E6E6 !important;
  border-radius: 2px !important;
  color: #4f4f4f !important;
  border: 1px solid #E6E6E6 !important;
  padding: 2px 5px !important;
}

.react-tagsinput {
  &.disabled {
    background-color: #e9ecef;
  }
}

.item .select-line {
  position: absolute;
  width: 4px;
  height: 36px;
  right: 25px;
  bottom: 0;
  background-color: #0065cb;
}

.ctd-table {
  .table-header {
    min-width: 100%;
    padding: 5px 0;
    max-height: 35px;

    & > div {
      padding: 0 10px;
      border-right: 1px solid #C4C4C4;
      font-size: 11px;
      line-height: 13px;
      font-weight: bold;
      color: #4F4F4F;
      text-transform: uppercase;

      &.status {
        width: 35px;
      }

      &.codes {
        width: 110px;
      }

      &.titles {
        width: calc(100% - 145px);
        border-right: 0;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;

    .checkbox {
      min-width: 16px;
      height: 16px;
      background-image: url("../../assets/images/empty-checkbox-gray.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    input[type=checkbox] {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 16px;
      height: 16px;
    }

    input {
      &:checked {
        ~ {
          .checkbox {
            background-image: url("../../assets/images/checked-box.svg");
          }
        }
      }
    }

    span {
      margin-left: 10px;
      font-weight: bold;
      color: #7b7b7b;
    }

    &.checked {
      & ~ div {
        color: #0a6cce !important;
      }
    }

    &.applied {
      input {
        &:checked {
          ~ {
            .checkbox {
              background-image: url("../../assets/images/checked-box-gray.svg");
            }
          }
        }
      }

      & ~ div {
        color: #C4C4C4 !important;
      }
    }
  }

  .table-body {
    min-width: 100%;

    .table-row {
      display: flex;
      border-bottom: 1px solid #C4C4C4;

      label {
        width: 35px;
      }

      & > div, & > label {
        padding: 12px 10px;
        font-size: 14px;
        line-height: 17px;
        font-weight: bold;
        color: #4F4F4F;

        &.codes {
          width: 110px;
        }

        &.ctd-title {
          width: calc(100% - 145px);
          border-right: 0;
        }
      }
    }
  }
}

.filter_box_main {
  display: flex;
  align-items: flex-start;
}

div.filter-box {
  border-bottom: 1px solid #0066cc;
}

ul.filter_box {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10%;

    input {
      margin-right: 5px;
    }

    span {
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.filter_title {
  width: 92%;
  display: inline-block;
}

.filter_main {
  min-width: 222px;
  background: #fff 0 0 no-repeat padding-box;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 101, 203, 0.37);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 101, 203, 0.37);
  box-shadow: 0 0 5px 0 rgba(0, 101, 203, 0.37);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border: 0;
  opacity: 1;
  margin-top: 5px;
  padding: 10px;
  font-weight: 500;
  text-align: center;
}

.filter_box_clearBtn {
  margin-top: 5px;
  font-size: 16px;
  color: #b5b5b5;
}

.admin-panel__body {
  .MuiTabs-flexContainer ~ span {
    background-color: $mainBlue !important;
  }
}

.MuiSnackbar-root {
  .MuiSnackbarContent-root {
    padding: 15px;
    border: 1px solid #7FC4BC;
    background-color: #fff !important;
    border-radius: 6px;
    color: #232426;
    font-weight: bold;

    svg {
      fill: #2A9D8F;
      margin-right: 10px;
    }
  }
}

.MuiTooltip-tooltip {
  font-size: 13px !important;
}

.error-snackbar {
  @include alert();
}

.main-color {
  color: #0066CC;
}

.main-action-btn {
  @include btn-style(#0066CC);
}

.iconTitle {
  font-weight: bold;
  font-size: 20px;
  margin-right: 10px;
}

.secondary-action-btn {
  @include btn-style($secondary);
}

.main-action-btn {
  @include btn-style($mainBlue);

  &.filter-btn {
    font-size: 13px;

    svg {
      max-width: 1rem;
    }
  }
}

.no-data {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 5%;
}

div[role=tooltip] {
  z-index: 1301;
}