.sub-header {
  width: 100%;
  height: 26px;
  background: #ffffff;
  color: #000000;
  display: flex;
  box-shadow: 0.4px 1px 3px 1px rgba(0, 102, 204, 0.2);
  position: fixed;
  top: 50px;
  left: 0;
  z-index: 8;

  &-display-none {
    display: none;
  }
}