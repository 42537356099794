@import "variables";

@mixin page-title {
  font-weight: 400;
  font-size: 18px;
  color: #757575;
}

@mixin content-header {
  background-color: #0065cb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;

  > div {
    display: flex;
    align-items: center;

    span {
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

@mixin breadcrumbs {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: #878787;
  
}

@mixin modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 516px;
  min-height: 273px;

  .modal-header {
    background-color: #0066CC;
    min-height: 48px;
    width: 100%;
    position: relative;
    padding: 0;
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .modal-title {
      text-transform: uppercase;
      color: #fff;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 17px;
      margin-left: 9px;
    }

    .close-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 48px;
      height: 47px;
      background-color: #0052A3;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: max-content;
    width: 100%;
    padding: 20px;

    .warning-container {
      display: flex;
      justify-content: center;
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #E51414B0;
        margin-left: 9px;
      }
      svg {
        width: 15px;
      }
    }

    .choice-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 30px;

      .choice-item {
        margin-bottom: 18px;
      }
    }

    .buttons-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
    }

    .form-content {
      margin-top: 30px;

      .item {
        &.w-70 {
          width: 70%;
        }

        label {
          color: #878787;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .filled {
          input {
            &::placeholder {
              display: none;
            }
          }
        }
      }

      .select-line {
        height: calc(100% - 32px);

        &:after {
          position: absolute;
          content: url("../../assets/images/arrow.svg");
          left: 12px;
          top: 7px;
        }
      }

      label.checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        margin-top: 10px;
        position: relative;
        cursor: pointer;

        div.checkbox {
          width: 16px;
          height: 16px;
          background-image: url("../images/empty-checkbox.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }

        input[type=checkbox] {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          width: 16px;
          height: 16px;
        }

        input {
          &:checked {
            ~ {
              .checkbox {
                background-image: url("../images/checked-box.svg");
              }
            }
          }
        }

        span {
          font-weight: bold;
          color: #7b7b7b;
        }
      }
    }
  }

  .checkbox-label {
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 12px;
      color: #000;
    }
    span.color-blue {
      color: #0066CC !important;
    }
    input,
    .checkbox {
      cursor: pointer;
    }
  }

  .project-container-info {
    padding-bottom: 15px;
    padding-top: 15px;
    font-weight: 500;
    text-align: center;
    line-height: 2;

    .uppercase-text {
      text-transform: uppercase;
    }

    span.color-blue {
      color: #0066cc !important;
    }
  }

  &.large {
    min-height: 400px;
  }

  &.small {
    min-height: 230px;
  }
}

@mixin alert {
  .MuiSnackbarContent-root {
    padding: 15px;
    border: 1px solid #F1A997;
    background-color: #fff !important;
    border-radius: 6px;
    color: #232426;
    font-weight: bold;

    svg {
      fill: #E76F51;
      margin-right: 10px;
    }

    & > div {
      width: 100%;
      text-align: center;

      &.error-message-content {
        text-align: left;
      }
    }
  }

  &.error-dialog {
    max-width: 500px;
    z-index: 26;
    top: 50%;
    transform: translate(-50%, -50%);

    & ~ .backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 25;
    }
  }
}

@mixin btn-style($color) {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $color;
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
  line-height: 17px;
  padding: 9px 15px;
  text-transform: uppercase;
  height: 35px;
  max-height: 35px;
  min-width: 100px;
  border-radius: 6px;
  margin-left: 10px;

  svg, img {
    margin-right: 5px;
  }

  @if $color == #0066cc {
    background-color: $color;
    color: #fff;

    &:hover {
      background-color: #0052A3;
      border-color: #0052A3;
    }
    &:active {
      background-color: #003D7A;
    }
    &:disabled {
      background-color: #EFF0F2;
      border-color: #EFF0F2;
      color: #ADB5BD;
    }
  } @else {
    background-color: #fff;
    color: $color;

    &:hover {
      background-color: #8A9197;
      border-color: #8A9197;
      color: #fff;
    }
    &:active {
      background-color: #686D71;
    }
  }
}