.text-uppercase {
    text-transform: uppercase !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.m-0 {
    margin: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.mb-2 {
    margin-bottom: 1.5rem !important;
}

.d-none {
    display: none !important;
}

.w-100 {
    width: 100% !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.b-none {
    border: unset !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}