@media all and (max-width: 1921px) {
  .title {
    background-color: #0065cb;
    color: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
  }

  .newDocument__container {
    padding-right: 0;
    padding-left: 0;

    .buttons-container {
      display: flex;
    }
    .buttons-container-breeze-form {
      display: flex;
      position: static;
      padding-left: 1440px;
      padding-bottom: 25px;
      padding-top: 25px;
    }
  }
}

@media all and (max-width: 1800px) {
  .formBlock {
    .left-content, .right-content {
      .field-item {
        &.big-size, &.middle-size {
          max-width: calc(33% - 15px) !important;
          flex-basis: calc(33% - 15px) !important;
        }

        &.small-size {
          max-width: calc(17% - 45px) !important;
          flex-basis: calc(17% - 45px) !important;
        }

        &:last-child {
          margin-right: 0;
        }

        &.icon-item {
          margin-right: 0 !important;
        }
      }
    }
  }
}

@media all and (max-width: 1601px) {
  .title {
    background-color: #0065cb;
    color: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
  }

  .newDocument__container {
    padding-right: 0;
    padding-left: 0;

    .buttons-container {
      display: flex;
    }
    .buttons-container-breeze-form {
      display: flex;
      position: static;
      padding-left: 1100px;
      padding-bottom: 25px;
      padding-top: 25px;
    }
  }
  .breeze_data {
    padding-top: 25px;
  }
}

@media all and (max-width: 1537px) {
  .title {
    background-color: #0065cb;
    color: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
  }

  .newDocument__container {
    padding-right: 0;
    padding-left: 0;

    .buttons-container {
      display: flex;
    }
    .buttons-container-breeze-form {
      display: flex;
      position: static;
      padding-left: 1024px;
      padding-bottom: 25px;
      padding-top: 25px;
    }
  }
}

@media all and (max-width: 1441px) {
  .title {
    background-color: #0065cb;
    color: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
  }

  .newDocument__container {
    padding-right: 0;
    padding-left: 0;

    .buttons-container {
      display: flex;
    }
    .buttons-container-breeze-form {
      display: flex;
      position: static;
      padding-left: 924px;
      padding-bottom: 25px;
      padding-top: 25px;
    }
  }
}

@media all and (max-width: 1372px) {
  .title {
    background-color: #0065cb;
    color: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
  }

  .newDocument__container {
    padding-right: 0;
    padding-left: 0;

    .buttons-container {
      display: flex;
    }
    .buttons-container-breeze-form {
      display: flex;
      position: static;
      padding-left: 890px;
      padding-bottom: 25px;
      padding-top: 25px;
    }
  }
}

@media all and (max-width: 1367px) {
  .title {
    background-color: #0065cb;
    color: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
  }

  .newDocument__container {
    padding-right: 0;
    padding-left: 0;

    .buttons-container {
      display: flex;
    }
    .buttons-container-breeze-form {
      display: flex;
      position: static;
      padding-left: 886px;
      padding-bottom: 25px;
      padding-top: 25px;
    }
  }
}

@media all and (max-width: 1300px) {
  #model-doc-form {
    .metadata-form {
      .content {
        flex-wrap: wrap;

        .model-doc-data, .submission-expert-data {
          flex-basis: 100%;
          max-width: 100%;
        }

        .submission-expert-data {
          border-left: 0;
          border-top: 1px solid #B0E2FA;
          padding-top: 15px;
        }
      }
    }
  }

  .formBlock {
    .left-content, .right-content {
      .field-item {
        &.big-size, &.middle-size {
          max-width: calc(50% - 15px) !important;
          flex-basis: calc(50% - 15px) !important;
        }

        &.small-size {
          max-width: 30% !important;
          flex-basis: 30% !important;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .dt-filters-container {
    flex-wrap: wrap;
    height: unset !important;
    padding: 10px !important;

    .dt-filter-left {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 10px;
    }

    .dt-filter-right {
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: flex-end;
    }
  }

  .manage-roles, .style-mapping {
    div.option {
      min-width: unset;
    }
  }
}

@media all and (max-width: 1281px) {
  .title {
    background-color: #0065cb;
    color: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
  }

  .newDocument__container {
    padding-right: 0;
    padding-left: 0;

    .buttons-container {
      display: flex;
    }
    .buttons-container-breeze-form {
      display: flex;
      position: static;
      padding-left: 800px;
      padding-bottom: 25px;
      padding-top: 25px;
    }
  }
}

@media all and (max-width: 991px) {
  #newDocumentForm {
    .sectionAndDescription {
      flex-direction: column;

      .descriptionPart {
        margin-left: 0;
      }
    }

    .wrapper-molecule,
    .wrapper-template {
      position: relative;
      max-width: unset !important;
      grid-template-columns: unset !important;
    }

    .wrapper-molecule .vert-line,
    .wrapper-template .vert-line {
      top: 0;
      right: 25px;
    }
  }
}

@media all and (max-width: 991px) {
  .dt-project-table-tr {
    & > div:last-child {
      padding-left: 0;

      .dt-optionicon {
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .option-container {
    display: flex !important;
  }
}

